     /* Subscriptions styles */
.subscription-container {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 5vh;
}

.subscription-container h2 {
    margin-top: 0;
    color: #004d40;
    
}

.subscription-container p {
    margin-top: 0;
    color: #004d40;
    text-align: center;
    font-size: 16px;
}

.due-date {
    font-weight: bold;
    color: #666;
}

.expense-description {
    margin-bottom: 10px;
    font-size: 16px;
}

.icon-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.icon-amount .left-side {
    display: flex;
    align-items: center;
}

.icon-amount .left-side img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.icon-amount .amount {
    margin-right: auto;
    font-size: 18px;
    color: #333;
}

.icon-amount .receipt {
    display: flex;
    align-items: center;
}

.icon-amount .receipt img {
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.icon-amount button {
    background-color: #004d40;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.icon-amount button:hover {
    background-color: #00382e;
}

/* Pay Now Form Modal */
#pay-now-modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

#pay-now-modal h2 {
    margin-top: 0;
    color: #004d40;
}

#pay-now-modal form {
    display: flex;
    flex-direction: column;
}

#pay-now-modal label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

#pay-now-modal input {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

#pay-now-modal button {
    padding: 10px 20px;
    background-color: #004d40;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

#pay-now-modal button:hover {
    background-color: #00382e;
}

#overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 999;
}

/* Responsive styles */
@media (max-width: 480px) {
    .subscription-container {
        padding: 15px;
        margin-bottom: 15px;
    }

    .icon-amount .left-side img, .icon-amount .receipt img {
        width: 30px;
        height: 30px;
    }

    .icon-amount .amount {
        font-size: 14px;
    }

    .icon-amount button {
        font-size: 0.8rem;
        padding: 6px 12px;
    }

    #pay-now-modal {
        width: 90%;
    }

    #pay-now-modal input, #pay-now-modal button {
        font-size: 0.8rem;
    }
}

@media (max-width: 580px) {
    #add-subscription-btn {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
}

.outer-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 42vh;
    margin-right: 12vh;
    margin-bottom: 5vh;
}

@media (max-width: 768px) {
    .outer-container {
        margin-left: 5vh;
        margin-right: 5vh;
        padding: 15px;
    }
}

.toggle-form-button, .view-form-details-button {
    background-color: #004d40;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.toggle-form-button:hover, .view-form-details-button:hover {
    background-color: #003300;
}

@media (max-width: 480px) {
    .toggle-form-button, .view-form-details-button {
        font-size: 0.8rem;
        padding: 6px 10px;
    }
}

.subscription-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.subscription-details {
    flex: 1;
}

.subscription-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    
    border-radius: 50%; /* Make it circular */
    margin-right: 10px; /* Space between icon and text */
    font-size: 24px; /* Adjust icon size */
    color: #004d40; /* Icon color */
}
.pay-now-button {
    margin-left: auto; /* Pushes the button to the far right */
}

.calendar-and-subscription-container h2 {
    border-bottom: 2px solid #2E8B57; /* Dark green border */
    padding-bottom: 8px; /* Add some padding for spacing */
    margin-bottom: 16px; /* Space between the border and the content below */
}

.subscription-due-date {
    font-size: 14px;
    color: #e70b0b;
    margin-top: 4px;
}
