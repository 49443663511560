/* InsightsPage.css */

/* Container for the insights page */
.insights-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Header styling */
.insights-header {
    text-align: center;
    margin-bottom: 30px;
}

.insights-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

/* Insight item styling */
.insight-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 20vw;
}

.insight-item h2 {
    font-size: 1.8rem;
    color: #222;
    margin-bottom: 10px;
}

.insight-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.insight-item em {
    font-style: italic;
    color: #888;
}

/* Responsive styling */
@media (max-width: 768px) {
    .insights-container {
        padding: 10px;
    }

    .insight-item {
        padding: 10px;
    }

    .insights-header h1 {
        font-size: 2rem;
    }
}
