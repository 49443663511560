/* Container for profile and form */
.profile-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 90%; /* Adjusted width for responsiveness */
    max-width: 600px; /* Max width for larger screens */
    color: #004d40;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

/* Header styling */
h1 {
    text-align: center;
    font-style: italic;
    font-weight: bold;
    font-size: 1.5em; /* Adjusted size for better responsiveness */
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    background: linear-gradient(to right, #004d00, #66ff66);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px; /* Space for better readability */
}

/* Label styling */
label {
    display: block;
    margin: 10px 0 5px;
    color: #004d00;
}

/* Input fields styling */
input[type="text"], input[type="email"], input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 2px solid #004d00;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #f0f0f0;
}

/* Button styling */
button {
    padding: 10px;
    background-color: #004d40;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
}

/* Button hover effect */
button:hover {
    background-color: #003300;
}

/* Profile picture preview */
.profile-picture-preview {
    text-align: center;
    margin: 15px 0;
}

/* Profile picture styling */
.profile-picture-preview img {
    max-width: 100px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profile-container {
        width: 95%; /* Adjust width for smaller screens */
        max-width: none; /* Remove max-width restriction */
        padding: 15px; /* Reduce padding */
    }

    h1 {
        font-size: 1.2em; /* Smaller font size for smaller screens */
    }
}
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
}

h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-picture img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
}

.profile-details {
    font-size: 1.2rem;
    color: #555;
}

.profile-details h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.profile-details p {
    margin-top: 5px;
    font-size: 1.1rem;
    color: #777;
}

.profile-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
}

.profile-form label {
    margin-top: 10px;
    font-weight: bold;
}

.profile-form input[type="text"],
.profile-form input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile-form button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.profile-form button:hover {
    background-color: #555;
}

.edit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #004d40;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #045b26;
}
