.cashflow-container {
  padding: 20px;
  border: 8px;
  margin-top: 15vh;
  margin-bottom: 20vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin-left: 20vw;
  margin-right: 20px;
  min-height: 600px;
  height: auto;
}

/* Styles for the container */
.container {
  margin: auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 1400px;
  margin-top: 10vh;
  min-height: 800px;
}

/* Styles for the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

/* Styles for table cells */
th, td {
  padding: 10px;
  text-align: left;
}

/* Styles for table headers */
th {
  background-color: #00796b; /* Calendar header color */
  color: white;
}

/* Styles for columns */
.description-column {
  width: 66%;
}

.amount-column {
  width: 33%;
}

/* Styles for total rows */
.total-row {
  font-weight: bold;
  text-align: right;
}

/* Styles for empty rows */
.empty-row {
  background-color: #00796b; /* Calendar background color */
  color: white;
}

/* Styles for add buttons */
.add-btn {
  cursor: pointer;
  font-size: 18px;
  color: #ffffff; /* White color for button in calendar style */
}

/* Styles for add category buttons */
.add-category-btn {
  margin: 10px 0;
  cursor: pointer;
  color: #00796b; /* Calendar link color */
  text-decoration: underline;
}

/* Styles for smaller screens */
@media (max-width: 767px) {
  .cashflow-container {
      margin-top: 10vh;
      margin-left: 10px;
      margin-right: 10px;
      height: auto;
      min-height: auto;
      margin-bottom: 10vh;
      padding: 15px;
  }

  .container {
      padding: 10px;
      margin-top: 5vh;
      min-height: 600px;
  }

  table {
      font-size: 14px;
  }

  th, td {
      padding: 8px;
  }

  .description-column, .amount-column {
      width: 100%;
      display: block;
  }
}

/* Styles for the category header and + icon */
.category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.category-header h2 {
  margin: 0;
}

.icon-add {
  color: darkgreen; /* or any green color of your choice */
  font-size: 24px; /* Adjust size as needed */
  cursor: pointer;
  margin-right: 10px;
}

/* Align + icon to the right */
.category-header .icon-add {
  margin-left: auto;
}
