.policy-content {
    padding: 2rem;
    margin-top: 5rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.policy-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.policy-content h2 {
    font-size: 2rem;
    margin-top: 2rem;
}

.policy-content p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.policy-content img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.policy-content .policy-section {
    margin-top: 3rem;
}

.policy-content .policy-section .icon {
    font-size: 3rem;
    color: #004d40;
    margin-bottom: 1rem;
}

