   

      .wrapper {
            display: flex;
            flex-direction: column;
            height: 100vh;
        }

        

        .content {
            
            padding: 2rem;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-left: 20%; /* Offset for the sidebar on wider screens */
        }

        @media (max-width: 767px) {
            .content {
                margin-left: 0;
            }
        }

        .help-header {
            text-align: center;
        }

        .help-header h1 {
            font-size: 2.5rem;
            color: #004d40;
        }

        .faq {
            margin-top: 2rem;
        }

        .faq h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        .faq-item {
            margin-bottom: 1rem;
        }

        .faq-item h3 {
            font-size: 1.25rem;
            font-weight: bold;
        }

        .faq-item p {
            margin-top: 0.5rem;
            font-size: 1rem;
        }

        .contact-info {
            margin-top: 3rem;
            text-align: center;
        }

        .contact-info h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        .contact-info p {
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }

        .contact-info .contact-method {
            margin-bottom: 1rem;
        }

        .contact-info .contact-method i {
            font-size: 1.5rem;
            margin-right: 0.5rem;
        }

        .contact-info form {
            max-width: 600px;
            margin: 0 auto;
            padding: 1rem;
            background: white;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .contact-info form .form-group {
            margin-bottom: 1rem;
        }

        .contact-info form .form-group label {
            font-size: 1rem;
        }

        .contact-info form .form-group input,
        .contact-info form .form-group textarea {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
        }

        .contact-info form .form-group textarea {
            height: 150px;
        }

        .contact-info form button {
            background-color: #004d40;
            color: white;
            border: none;
            padding: 0.75rem 1.5rem;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
        }

        .contact-info form button:hover {
            background-color: #003d32;
        } 
