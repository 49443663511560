
.pillar-footer {
            background-color: #004d40; /* Dark green */
            color: white;
            text-align: center;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8vh; /* Footer height */
            box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
            z-index: 999;
        }

        @media (min-width: 768px) {
            .pillar-footer {
                width: 80%; /* Align with the content width */
                left: 20%;
            }
        } 
.pillar-footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.pillar-footer-links {
  margin-top: 10px;
}

.pillar-footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.pillar-footer-links a:hover {
  text-decoration: underline;
}

