.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10vh; /* Header height: 1/10th of viewport height */
  background-color: #004d40; /* Dark green */
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.header .logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.header .nav-links {
  display: none;
}

.header .hamburger {
  display: block;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Styles for wider screens */
@media (min-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 20%;
    height: 150vh; /* Full viewport height */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    z-index: 1000;
    
  }

  .header .logo {
    margin-bottom: 2rem;
  }

  .header .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }

  .header .nav-links a {
    color: white;
    text-decoration: none;
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #00695c; /* Slightly lighter green */
    transition: background-color 0.3s;
  }

  .header .nav-links a:hover {
    background-color: #00695c;
  }

  .header .hamburger {
    display: none;
  }
}

/* Styles for smaller screens */
@media (max-width: 767px) {
  .header {
    height: 8vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #004d40; /* Dark green */
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    text-align: center;
  }

  .header .nav-links.nav-open {
    display: flex;
  }

  .header .nav-links a {
    color: white;
    text-decoration: none;
    padding: 1rem;
    border-bottom: 1px solid #00695c; /* Slightly lighter green */
    transition: background-color 0.3s;
  }

  .header .nav-links a:hover {
    background-color: #00695c;
  }
}

/* Main Content and Footer Styles */
.main-content {
  margin-top: 0vh; /* Space for the header */
  margin-left: 0;
  padding: 0px;
  transition: margin-left 0.3s;
}

@media (min-width: 768px) {
  .main-content {
    margin-left: 20%; /* Space for the header when on the left */
    margin-top: 0;
  }
}

.prof-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.prof-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}