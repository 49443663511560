
body {
    background-image: url('stock.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
}
/* Main Content and Footer Styles */
.sign-content {
    margin-top: 10vh; /* Space for the header */
    padding: 20px;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    min-height: 80vh; /* Ensure it takes up at least 80% of the viewport height */
    transition: margin-left 0.3s;
}

@media (min-width: 768px) {
    .sign-content {
        margin-left: 20%; /* Space for the header when on the left */
        margin-top: 0;
    }
}


/* Form Container Styles */
.form-container {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.5s ease-in-out; /* Smooth transition for transform */
    margin-top: 10vh;
}

.header-content {
    background-color: #00695c; /* Dark teal */
    color: white;
    padding: 15px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.header-content h3 {
    margin: 0;
    font-size: 1.5rem;
}

.form-container .btn-primary {
    background-color: #00695c;
    border: none;
    margin-top: 15px;
}

.form-container .btn-primary:hover {
    background-color: #004d40;
}

.form-container a {
    display: block;
    text-align: center;
    margin-top: 10px;
}

/* Responsive Zoom Effect */
@media (min-width: 992px) { /* Large screens (desktops) */
    .form-container {
        transform: scale(1.1); /* Zoom in slightly */
    }
}

@media (min-width: 1200px) { /* Extra large screens (large desktops) */
    .form-container {
        transform: scale(1.2); /* Zoom in more */
    }
}

@media (max-width: 768px) { /* Medium screens (tablets) */
    .form-container {
        padding: 20px;
    }

    .header-content h3 {
        font-size: 1.25rem; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 576px) { /* Small screens (phones) */
    .header-content h3 {
        font-size: 1.1rem; /* Further adjust font size for very small screens */
    }
}
