.challenge-header {
    background-color: #004d40;
    color: white;
    padding: 1rem 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.logo {
    font-size: 2.2em;
    font-weight: 700;
}

.challenge-footer {
    background-color: #004d40;
    color: white;
    padding: 1rem 2rem;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: auto;
}

.challenge-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 5vh auto; /* Center the content horizontally on smaller screens */
    padding: 2rem;
    max-width: 800px;
    margin-left: 30vw;
    width: 90%; /* Ensure content takes up 90% of the screen width on smaller screens */
}

.change-header {
    margin-bottom: 1.5rem;
    text-align: center;
}

.challenge-title {
    font-size: 1.8em;
    font-weight: 700;
    color: #004d40;
    margin-bottom: 0.5rem;
}

.challenge-description {
    font-size: 1em;
    color: #555;
    margin: 0;
}

.number-grid {
    display: grid;
    grid-template-columns: repeat(10, 2fr);
    grid-template-rows: repeat(5, 2fr);
    gap: 5px;
    margin-top: 3vh;
    justify-items: center;
    margin-bottom: 1.5rem;
}

.number {
    background-color: #004d40;
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    font-size: 1.1em;
    width: 60px;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.number.clicked {
    background-color: #00695c;
    cursor: default;
}

.total-amount,
.input-group {
    margin: 1rem 0;
    text-align: center;
}

.total-amount,
.goal-info {
    font-size: 1.4em;
    font-weight: 700;
    color: #004d40;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1em;
    color: #555;
}

.input-group input {
    padding: 0.5rem;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 0.5rem;
    width: 100%;
    max-width: 300px;
}

.input-group button {
    padding: 0.5rem 1rem;
    font-size: 1em;
    border: none;
    border-radius: 4px;
    background-color: #004d40;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.input-group button:hover {
    background-color: #00695c;
}

.hidden {
    display: none;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .challenge-content {
        padding: 1rem;
        margin: 2vh auto;
        width: 95%;
    }

    .challenge-title {
        font-size: 1.6em;
    }

    .number {
        width: 50px;
        height: 50px;
        padding: 10px;
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .challenge-content {
        padding: 0.5rem;
        margin: 1vh auto;
        margin-top: 10vh;
        width: 98%; /* Full-width on small screens */
    }

    .challenge-title {
        font-size: 1.4em;
    }

    .number-grid {
        grid-template-columns: repeat(5, 2fr); 

    .number {
        width: 40px;
        height: 40px;
        padding: 8px;
        font-size: 0.9em;
    }

    .total-amount {
        font-size: 1.5em;
    }
    }
}
