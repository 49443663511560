 .title-section {
            background: #f1f1f1;  
            text-align: center;
            border-bottom: 5px solid #ddd;
            background-color: #f1f1f1;
            margin-top: 12vh;
        }

        .title-section h2 {
            font-size: 2rem;
            margin: 0;
            background-color: #004d40;
            font-family: emoji;
            color: #fff;
            padding: 25px;
        }

       

        .slide-container {
            position: relative;
            width: 100%;
            overflow: hidden;
        }

        .slides {
            display: flex;
            transition: transform 0.5s ease-in-out;
            width: 100%;
        }

      
        .slide h2 {
            color: #2e8b57; /* Dark green for headers */
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: emoji;
        }

        .slide p {
            font-size: 1.1rem;
            color: #004d40;
            margin-bottom: 1rem;
            text-align: center;
            max-width: 80%;
            font-family: emoji;
        }

        .slide ul {
            font-size: 1.1rem;
            color: #004d40;
            list-style-type: disc;
            padding-left: 1.5rem;
            max-width: 80%;
            font-size: 2rem;
            font-family: emoji;
        }

        .slide ul li {
            margin-bottom: 0.5rem;
            color: #004d40;
            font-size: 1.1rem;
            font-family: emoji;
        }

        .controls {
            position: absolute;
            top: 50%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            transform: translateY(-50%);
        }

        .control-button {
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            border: none;
            padding: 1rem;
            cursor: pointer;
            border-radius: 50%;
            font-size: 1.5rem;
        }
